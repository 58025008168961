import * as React from 'react';
import { __ } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { MetaTags, CartContactInfoPart, EmptyCart } from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { updateCart } from './cartSlice';

interface Props {
  user: any;
  dispatch: any;
  data: ThenArg<typeof API.getCart> | null;
  isFetching: boolean;
  deliveryAddresses: any;
  deliveryAddressesIsFetching: boolean;
  showProblems: boolean;
  dispatchCartContactInfo: any;
  cartContactInfo: any;
  setCartState: any;
}

class CartSecondStepContainer extends React.Component<Props> {
  public render() {
    const {
      user,
      data,
      isFetching,
      deliveryAddresses,
      deliveryAddressesIsFetching,
      showProblems,
      dispatchCartContactInfo,
      cartContactInfo,
      setCartState,
    } = this.props;

    if (!data) {
      return null;
    }

    const { items } = data;

    return (
      <>
        <MetaTags tags={{ title: __('Košík - Osobné údaje') }} />
        {items.length === 0 ? (
          <EmptyCart />
        ) : (
          <CartContactInfoPart
            data={data}
            updateCart={this.updateCart}
            deliveryAddresses={deliveryAddresses}
            showProblems={showProblems}
            dispatchCartContactInfo={dispatchCartContactInfo}
            cartContactInfo={cartContactInfo}
            setCartState={setCartState}
            isB2b={!!(user && user.b2b)}
            eshopUser={user && user.eshop_user}
          />
        )}
      </>
    );
  }

  private updateCart = data => {
    this.props.dispatch(updateCart(data, true, false));
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CartSecondStep' })(CartSecondStepContainer),
);
