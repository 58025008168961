import * as React from 'react';
import { __, __r } from 'react-i18n';
import { Link, withRouter, WithRouterProps } from 'react-router';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  HeaderLogo as HeaderSmallLogo,
  TextP,
  FlexRow,
  FlexColCenter,
  FlexCol,
  IconImg,
  SearchForm,
  prop,
  ZemplinLoaderWrapper,
  SearchOnlySwitch,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import HeaderCart from './HeaderCart';
import HeaderProfile from './HeaderProfile';
import { Navigation } from './Navigation';
import { DesktopWrapper } from '../_helpers/Default/ResponsiveWrappers';
import { CART_ROUTES } from '../../containers/Cart/utilities';
import { theme } from '../../theme/theme';
import { formatPriceToString } from '../../utilities/index';

interface Props {
  lang: string;
  items: ThenArg<typeof API.loadTree>;
  dispatch: (action: any) => void;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  contact: {
    email: string;
    phone: string;
  };
  toggleResponsiveCategoryMenu: any;
  handleSearchFocus: any;
  hideSearchResultsViewer: () => void;
  resultsDropdownVisible: boolean;
  searchTerm: string;
  handleSearchTermChange: (e: any) => void;
  redirectToSearchResultsPage: (e: any) => void;
  changeDimmerVisible: (newVal: boolean) => void;
  partialSearchCategoryProducts: ThenArg<typeof API.searchProducts>;
  partialSearchProducts: ThenArg<typeof API.searchProducts>;
  partialSearchProductsIsFetching: boolean;
  partialSearchCategories: ThenArg<typeof API.loadCategories>;
  partialSearchCategoriesIsFetching: boolean;
  user: any;
  cartData: ThenArg<typeof API.getCart>;
  resetSearchTerm: any;
  cartIsImporting: boolean;
}

function DesktopHeader({
  user,
  contact: { phone, email },
  handleSearchFocus,
  hideSearchResultsViewer,
  resultsDropdownVisible,
  searchTerm,
  handleSearchTermChange,
  redirectToSearchResultsPage,
  changeDimmerVisible,
  items,
  partialSearchProducts,
  partialSearchProductsIsFetching,
  partialSearchCategories,
  partialSearchCategoriesIsFetching,
  cartData,
  location,
  resetSearchTerm,
  partialSearchCategoryProducts,
  cartIsImporting,
}: Props & WithRouterProps) {
  const isB2B = user && user.b2b;
  const logoSrc = '/images/zemplin/zemplin27.svg';
  const uploadSrc = '/images/zemplin/upload.svg';
  const {
    items: cartItems,
    currency,
    total_items_price_without_vat,
    total_items_price,
  } = cartData || {};
  const cartRoute = CART_ROUTES[prop(cartData, 'step', 1)];

  React.useEffect(() => {
    const { pathname } = location;
    if (!pathname.includes(__r('routes:vyhladavanie', 'vyhladavanie'))) {
      resetSearchTerm();
    }
  }, [location, resetSearchTerm]);

  const isLoggedIn = !!user;

  return (
    <HeaderWrapper>
      <StyledDesktopWrapper>
        <UpperHeader>
          <UpperHeaderWrapper isLoggedIn={isLoggedIn}>
            <HeaderSmallLogo logo={logoSrc} />
            <MiddleWrapper>
              <NeedHelp>
                <SmallTextP>
                  <b>{__('Potrebujete poradiť?')}</b>
                </SmallTextP>
                <DotDelimeter />
                <SmallTextLink href={`tel:${phone}`}>{phone}</SmallTextLink>
                <DotDelimeter />
                <SmallTextLink href={`mailto:${email}`}>{email}</SmallTextLink>
              </NeedHelp>
              <SearchForm
                user={user}
                searchResults={{
                  productsResult: partialSearchProducts,
                  categoriesResult: partialSearchCategories,
                }}
                redirectToSearchResultPage={redirectToSearchResultsPage}
                searchTerm={searchTerm}
                handleSearchTermChange={handleSearchTermChange}
                handleFocus={handleSearchFocus}
                hideResultViewer={hideSearchResultsViewer}
                resultsDropdownVisible={resultsDropdownVisible}
                partialSearchProductsIsFetching={
                  partialSearchProductsIsFetching
                }
                partialSearchCategoriesIsFetching={
                  partialSearchCategoriesIsFetching
                }
                partialSearchCategoryProducts={partialSearchCategoryProducts}
              />
              {!!user && <SearchOnlySwitch />}
            </MiddleWrapper>
            <RightWrapper>
              <HeaderProfile />
              <HeaderCart
                cartRoute={cartRoute}
                numberOfProducts={cartItems ? cartItems.length : 0}
                totalPrice={
                  isB2B
                    ? total_items_price_without_vat
                      ? total_items_price_without_vat
                      : total_items_price
                    : total_items_price
                }
                currency={currency}
              />
            </RightWrapper>
          </UpperHeaderWrapper>
        </UpperHeader>
        <BottomHeader>
          <Navigation
            user={user}
            changeDimmerVisible={changeDimmerVisible}
            items={items}
          />
          {user && user.b2b ? (
            <ImportOrderButton
              to={__r('routes:import-objednavky', '/import-objednavky')}
            >
              {cartIsImporting ? (
                <>
                  <ZemplinLoaderWrapper
                    height={100}
                    loaderHeight={24}
                    strokeWidth={3}
                    center={true}
                    primaryColor={theme.colors.secondary}
                    secondaryColor={theme.colors.textPrimary}
                  />
                  <ImportOrderTitle>
                    {__('Práve prebieha import')}
                  </ImportOrderTitle>
                </>
              ) : (
                <>
                  <IconImg
                    cursor="pointer"
                    src={uploadSrc}
                    width={24}
                    height={24}
                    alt="upload-icon"
                  />{' '}
                  <ImportOrderTitle>
                    {__('Import objednávky / dopytu')}
                  </ImportOrderTitle>
                </>
              )}
            </ImportOrderButton>
          ) : null}
        </BottomHeader>
      </StyledDesktopWrapper>
    </HeaderWrapper>
  );
}

const StyledDesktopWrapper = styled(DesktopWrapper)`
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
`;

const HeaderWrapper = styled(FlexCol)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin: 0 auto;
  width: 100%;
  box-shadow: ${({ theme }) => theme.boxShadow.default};
  align-items: center;

  @media print {
    display: none;
  }

  ${({ theme }) => theme.mediab.l925`
      margin: 0;
  `}
`;

const UpperHeader = styled.div`
  border-bottom: ${({ theme }) => theme.borders.primary};
  width: 100%;
`;

const UpperHeaderWrapper = styled(FlexRow)<{ isLoggedIn?: boolean }>`
  padding:  ${props =>
    props.isLoggedIn ? `${rem(8)} ${rem(24)} ${rem(16)}` : rem(24)};
  justify-content: space-between;
  align-items: ${props => (props.isLoggedIn ? 'center' : 'flex-end')};
  width: 100%;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};

  // @media only screen and (max-width: 1740px) {
  //   max-width: ${rem(1080)};
  // }
  //
  // @media only screen and (max-width: 1300px) {
  //   max-width: ${rem(960)};
  // }

  margin: 0 auto;
`;

const BottomHeader = styled(FlexRow)`
  padding: 0 ${rem(24)};
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  max-width: ${({ theme }) => `${theme.grid.container.wide}px`};

  // @media only screen and (max-width: 1740px) {
  //   max-width: ${rem(1080)};
  // }
  //
  // @media only screen and (max-width: 1300px) {
  //   max-width: ${rem(960)};
  // }
`;

const ImportOrderButton = styled(Link)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.secondary};
  font-size: ${rem(14)};
  padding: ${rem(19)};
  line-height: ${rem(22)};
  text-decoration: none;

  max-width: ${rem(287)};
  width: 100%;
  display: flex;
  justify-content: center;
  max-height: ${rem(62)};
  transition: filter 0.2s ease;

  &:hover {
    filter: brightness(90%);
  }
`;

const ImportOrderTitle = styled.span`
  margin-left: ${rem(16)};
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
`;

const MiddleWrapper = styled(FlexColCenter)`
  width: 100%;
  max-width: ${rem(560)};
  margin: 0 ${rem(16)};

  // @media only screen and (max-width: 1740px) {
  //   max-width: ${rem(550)};
  // }
  //
  // @media only screen and (max-width: 1300px) {
  //   max-width: ${rem(450)};
  // }
`;

const RightWrapper = styled(FlexRowCenterVertical)``;

const NeedHelp = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(16)};
  justify-content: flex-start;
  width: 100%;
`;

const SmallTextP = styled(TextP)`
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.font.tertiary};
`;

const SmallTextLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textPrimary};
  margin: 0;
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.font.tertiary};

  &:hover {
    text-decoration: underline;
  }
`;

const DotDelimeter = styled.span`
  margin: 0 ${rem(12)};
  height: ${rem(2)};
  width: ${rem(2)};
  background-color: ${({ theme }) => theme.colors.textPrimary};
  border-radius: 50%;
  display: inline-block;

  ${({ theme }) => theme.mediab.m580`
    margin: 0 ${rem(8)};
  `}
`;

export default withRouter(DesktopHeader);
