import {
  MetaTags,
  TopInfoBanner,
  ZemplinLoaderWrapper,
  AddToCartModal,
  prop,
  InfoText,
} from 'eshop-defaults';
import * as React from 'react';
import styled from 'styled-components';
import * as cookie from 'react-cookies';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import {
  loadFooterData,
  setCurrency,
  loadDefaultSettings,
  setLanguage,
  resetToken,
  setTopText,
} from './actions';
import { CURRENCY_COOKIE } from './constants';
import {
  dimmerVisibilitySelector,
  currentThemeSelector,
  topTextSelector,
} from './selectors';
import Dimmer from '../../components/_helpers/Dimmer/Dimmer';
import { loadCategoriesTree } from '../Header/actions';
import Footer from '../Footer/Footer';
import { GlobalStyles } from '../../theme/globalStyles';
import { LogoMicrodata } from '@bart.sk-ecommerce/react-microdata';
// import { checksRedirect } from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';
import { theme } from '../../theme/theme';
import ContainerHeader from '../Header/Header';
import { rem } from 'polished';
import { USER_COOKIE } from 'react-auth';
import { loginUser } from 'react-auth/lib/containers/Auth/actions';
import { ThemeProvider } from 'styled-components';
import {
  fetchCart,
  addToCartModalVisibleSelector,
  setAddToCartModalVisibility,
  addToCartModalProductSelector,
  addItemToCartFromModal,
  addToCartModalIsRequestSelector,
  addToCartModalIsFromCategorySelector,
} from '../Cart/cartSlice';
import AddedToCartModal from '../Cart/AddedToCartModal';
import ImportCartModal from '../Cart/ImportCartModal';
import BreadCrumbContainer from '../BreadCrumb/BreadCrumb';
import API from '../../services/API';
import {
  checksRedirect,
  isSSR,
} from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';
import Cookies from '../../components/Cookies/Cookies';
import FacebookPagePlugin from '../../utilities/FacebookPagePlugin';
import { CART_COOKIE_ID } from '../Cart/helpers';
import AccountSelectWindow from '../../components/MyAccount/AccountSelectWindow';
import { __ } from 'react-i18n/lib';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';

interface Props {
  user: any;
  lang: string;
  props: any;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  metaTags: {
    description: string;
    keywords: string;
    og_article_author: string;
  };
  dispatch: any;
  menuItems: Array<{
    active: boolean;
    name: string;
    url: string;
    attribs: any;
  }>;
  isError: boolean;
  dimmerVisible: boolean;
  currentTheme: string;
  isLoaded: boolean;
  token: string;
  addToCartModal: boolean;
  addToCartModalProduct: any;
  addToCartModalIsRequest: boolean;
  addToCartIsFromCategory: boolean;
  topText: string;
  category: any;
  productDetail: any;
  cookies: any;
}

export const FillSpaceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  min-height: ${rem(600)};

  ${() => theme.mediab.l925`
     min-height: ${rem(400)};
  `}
`;

class App extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch, token, user, topText, cookies } = props;
    console.log('app inital: ', { user: props.user, cookies });

    try {
      dispatch(setLanguage('sk'));
      dispatch(setCurrency('EUR' || cookie.load(CURRENCY_COOKIE)));

      const cookiesAvailable = cookie.loadAll();
      const userCookie =
        prop(cookies, `${USER_COOKIE}`) || cookie.load(USER_COOKIE);
      if (userCookie) {
        API.setToken(userCookie);
      }

      console.log('app inital: ', API);
      console.log('app inital: ', { userCookie, cookiesAvailable });
      if (!token || !user) {
        if (userCookie) {
          await dispatch(loginUser(userCookie));
        } else if (
          prop(cookiesAvailable, 'cart_id') ||
          prop(cookiesAvailable, 'cc_cookie')
        ) {
          await dispatch(resetToken());
        }
        // if (process.env.NODE_ENV === 'development') {
        //   await dispatch(
        //     loginUser(
        //       '18fb7908f316ab95cc89d0b99b2a5c37e2c404c38940d2a43a1b3e76d6071bf5b4ebab9f71b7dcc6d521989a1b56e7e258e931319117cc1b8787725bf1bbc900da63b4ba72cd0e3cf6974ae0e929c7164e598480e329ef96eee0a474d25e3e98b626c4eb21a094823e2a9d907eb0881f56f53c19bc0794d97d2f216f3b749810',
        //     ),
        //   );
        // }
      }

      if (!topText) {
        const text = await API.loadOtherTexts('HEADER');
        await dispatch(setTopText(prop(text, 'content.json_content.body')));
      }

      try {
        await props.dispatch(checksRedirect(props.location));
      } catch (e) {
        // silence is marvelous
      }

      const cartCookie = prop(cookies, `${CART_COOKIE_ID}`);
      await Promise.all[
        ((await dispatch(loadDefaultSettings()),
        await dispatch(loadCategoriesTree()),
        await dispatch(loadFooterData())),
        await dispatch(fetchCart(true, cartCookie)))
      ];
      console.log('app initial is finished');
      return {
        props,
        isLoaded: true,
      };
    } catch (exp) {
      console.log(exp);
      return {
        isError: true,
        isLoaded: true,
      };
    }
  }

  public render() {
    const {
      children,
      dimmerVisible,
      socialUrls,
      user,
      currentTheme,
      isLoaded,
      token,
      dispatch,
      addToCartModal,
      addToCartModalProduct,
      addToCartModalIsRequest,
      addToCartIsFromCategory,
      topText,
      category,
      productDetail,
      lang,
    } = this.props;

    const currentThemeColors =
      !currentTheme || !theme[currentTheme]
        ? {
            primary: theme.colors.primary,
            navSecondary: theme.colors.navSecondary,
            paginationSecondary: theme.colors.paginationSecondary,
          }
        : {
            primary: theme[currentTheme].primary,
            navSecondary: theme[currentTheme].navSecondary,
            paginationSecondary: theme[currentTheme].paginationSecondary,
          };

    const currentThemeColorsFilters =
      !currentTheme || !theme[currentTheme]
        ? {
            color: theme.colors.primary,
            activeBgColor: theme.colors.primary,
            sliderTrackColor: theme.colors.primary,
            trackBorderColor: theme.colors.primary,
          }
        : {
            color: theme[currentTheme].primary,
            activeBgColor: theme[currentTheme].primary,
            sliderTrackColor: theme[currentTheme].primary,
            trackBorderColor: theme[currentTheme].primary,
          };
    const vtUrls = [
      '/novinky',
      '/aktuality',
      '/akcie',
      '/vypredaj',
      '/kontakt',
      '/tipy-a-rady',
    ];
    const url = window?.location?.pathname;
    let parentCategories;
    const categoryId =
      category?.data && category.data.categoryInfo?.category_id
        ? category.data.categoryInfo.category_id
        : null;

    if (category?.data && category.data.categoryInfo?.parent_categories) {
      parentCategories = category.data.categoryInfo.parent_categories;
    } else if (
      productDetail?.productDetail &&
      productDetail?.productDetail.parent_categories
    ) {
      parentCategories = productDetail?.productDetail.parent_categories;
    }

    let isVt = true;

    if (!parentCategories?.length && categoryId && categoryId !== 2) {
      isVt = false;
    } else if (
      parentCategories?.length &&
      parentCategories[0].category_id !== 2
    ) {
      isVt = false;
    } else {
      isVt = true;
    }

    if ((url && url === '/') || vtUrls.includes(url)) {
      isVt = true;
    }

    return (
      <React.Fragment>
        <Helmet />
        <LogoMicrodata
          url={process.env.REACT_APP_BASE_URL as string}
          logo={`${process.env
            .REACT_APP_BASE_URL as string}/images/zemplin/zemplin27.svg`}
        />

        <ThemeProvider
          theme={{
            ...theme,
            colors: {
              ...theme.colors,
              ...currentThemeColors,
            },
            categoryFilter: {
              ...theme.categoryFilter,
              ...currentThemeColorsFilters,
            },
          }}
        >
          <GlobalStyles />
          {this.props.metaTags && (
            <MetaTags
              tags={{
                ...this.props.metaTags,
                themeColor: currentThemeColors.primary,
              }}
            />
          )}

          {user && user.b2b && token ? (
            <TopInfoBanner
              colorGreen={false}
              message={topText ? topText : ''}
            />
          ) : null}
          {user && user.original_user && user.original_user.email ? (
            <TopInfoBanner
              colorGreen={true}
              message={`Ste prihlásený ako ${user ? user.firm : ''}`}
            />
          ) : null}
          <ContainerHeader />
          <BreadCrumbContainer />
          <FacebookPagePlugin isVt={isVt} />
          <FillSpaceWrapper>
            {!isLoaded ? <ZemplinLoaderWrapper height={300} /> : children}
          </FillSpaceWrapper>
          <Footer socialUrls={socialUrls} isVt={isVt} />
          {dimmerVisible && <Dimmer height={null} />}
          {addToCartModal && addToCartModalProduct && (
            <AddToCartModal
              product={addToCartModalProduct}
              handleClose={() => dispatch(setAddToCartModalVisibility())}
              isRequest={addToCartModalIsRequest}
              handleButtonClick={count =>
                dispatch(addItemToCartFromModal(parseFloat(count)))
              }
              isLoggedIn={user && user.b2b ? true : false}
              isFromCategory={addToCartIsFromCategory}
              user={user}
            />
          )}

          {!user || !user.b2b ? <AddedToCartModal dispatch={dispatch} /> : null}
          {user && user.b2b && <ImportCartModal dispatch={dispatch} />}

          {user && user.customer_type === 'OZ' ? (
            <AccountSelectWindow
              dispatch={dispatch}
              user={user}
              lang={lang}
              token={token}
            />
          ) : null}
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    socialUrls: state.general.socialUrls,
    metaTags: state.general.metaTags,
    lang: state.general.lang,
    dimmerVisible: dimmerVisibilitySelector(state),
    currentTheme: currentThemeSelector(state),
    addToCartModalProduct: addToCartModalProductSelector(state),
    addToCartModal: addToCartModalVisibleSelector(state),
    addToCartModalIsRequest: addToCartModalIsRequestSelector(state),
    addToCartIsFromCategory: addToCartModalIsFromCategorySelector(state),
    topText: topTextSelector(state),
    category: state.category,
    productDetail: state.productDetail,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'App' })(App),
);
