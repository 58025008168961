import * as React from 'react';
import { __r } from 'react-i18n';
import { withRouter, WithRouterProps } from 'react-router';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  HeaderLogo as HeaderSmallLogo,
  SearchForm,
  OutsideClick,
  MobileNavMenu,
  prop,
  SearchOnlySwitch,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import HeaderCart from './HeaderCart';
import HeaderProfile from './HeaderProfile';
import { MobileWrapper } from '../_helpers/Default/ResponsiveWrappers';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import { MobileNavigation } from './MobileNavigation';
import {
  IconType,
  SvgIcon,
} from 'eshop-defaults/lib/components/Zemplin/General/SvgIcon';
import { CART_ROUTES } from '../../containers/Cart/utilities';

interface Props {
  lang: string;
  items: ThenArg<typeof API.loadTree>;
  dispatch: (action: any) => void;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  contact: {
    email: string;
    phone: string;
  };
  toggleResponsiveCategoryMenu: any;
  searchTerm: string;
  handleSearchTermChange: (e: any) => void;
  redirectToSearchResultsPage: (e: any) => void;
  handleSearchFocus: any;
  hideSearchResultsViewer: () => void;
  resultsDropdownVisible: boolean;
  partialSearchProducts: ThenArg<typeof API.searchProducts>;
  partialSearchProductsIsFetching: boolean;
  partialSearchCategories: ThenArg<typeof API.loadCategories>;
  partialSearchCategoriesIsFetching: boolean;
  cartData: ThenArg<typeof API.getCart>;
  resetSearchTerm: any;
  user: any;
  partialSearchCategoryProducts: any;
  cartIsImporting: boolean;
}

/*****************/

const StyledMobileWrapper = styled(MobileWrapper)`
  width: 100%;
  margin: 0 auto;
  box-shadow: ${({ theme }) => theme.boxShadow.default};
`;

const HeaderWrapper = styled(OutsideClick)`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin: 0 auto;
  width: 100%;
  align-items: center;

  @media print {
    display: none;
  }
`;

const MobileMenuWrapper = styled(FlexRowCenterVertical)`
  width: 100%;
  padding: ${rem(16)};
  height: ${rem(72)};
  justify-content: space-between;
  align-items: end;
`;

const SearchWrapper = styled.div`
  width: 100%;
`;

const RightWrapper = styled(FlexRowCenterVertical)``;

const LeftWrapper = styled(RightWrapper)``;

function MobileHeader({
  searchTerm,
  handleSearchTermChange,
  redirectToSearchResultsPage,
  handleSearchFocus,
  hideSearchResultsViewer,
  resultsDropdownVisible,
  items,
  partialSearchProducts,
  partialSearchCategories,
  partialSearchProductsIsFetching,
  partialSearchCategoriesIsFetching,
  cartData,
  location,
  resetSearchTerm,
  user,
  partialSearchCategoryProducts,
  cartIsImporting,
}: Props & WithRouterProps) {
  const [isSearchVisible, setIsSearchVisible] = React.useState(false);
  const [isMenuVisible, setIsMenuVisible] = React.useState(false);

  const logoSrc = '/images/zemplin/zemplin27.svg';
  const cartRoute = CART_ROUTES[prop(cartData, 'step', 1)];

  const toggleMenu = () => {
    document.body.style.overflow = isMenuVisible ? 'visible' : 'hidden';
    setIsMenuVisible(!isMenuVisible);
  };

  const closeMenu = () => {
    document.body.style.overflow = 'visible';
    setIsMenuVisible(false);
  };

  React.useEffect(() => {
    const { pathname } = location;
    if (!pathname.includes(__r('routes:vyhladavanie', 'vyhladavanie'))) {
      resetSearchTerm();
    }
  }, [location, resetSearchTerm]);

  return (
    <StyledMobileWrapper>
      <HeaderWrapper handleFunction={hideSearchResultsViewer}>
        <MobileMenuWrapper>
          <LeftWrapper>
            <SvgIcon
              icon={IconType.listMenu}
              alt="Otvoriť menu"
              cursor={'pointer'}
              marginRight={24}
              // tslint:disable-next-line:jsx-no-lambda
              onClick={toggleMenu}
            />
            <HeaderSmallLogo logo={logoSrc} />
          </LeftWrapper>
          <RightWrapper>
            <SvgIcon
              icon={IconType.search}
              alt="Otvoriť vyhľadávanie"
              cursor={'pointer'}
              marginRight={16}
              // tslint:disable-next-line:jsx-no-lambda
              onClick={() => setIsSearchVisible(!isSearchVisible)}
            />
            <HeaderProfile />
            <HeaderCart
              cartRoute={cartRoute}
              numberOfProducts={2}
              totalPrice={900.99}
              currency={'EUR'}
            />
          </RightWrapper>
        </MobileMenuWrapper>
        {isSearchVisible && (
          <SearchWrapper>
            <SearchForm
              user={user}
              searchResults={{
                productsResult: partialSearchProducts,
                categoriesResult: partialSearchCategories,
              }}
              partialSearchProductsIsFetching={partialSearchProductsIsFetching}
              partialSearchCategoriesIsFetching={
                partialSearchCategoriesIsFetching
              }
              redirectToSearchResultPage={redirectToSearchResultsPage}
              searchTerm={searchTerm}
              handleSearchTermChange={handleSearchTermChange}
              handleFocus={handleSearchFocus}
              hideResultViewer={hideSearchResultsViewer}
              resultsDropdownVisible={resultsDropdownVisible}
              isMobile={true}
              partialSearchCategoryProducts={partialSearchCategoryProducts}
            />
            {!!user && <SearchOnlySwitch />}
          </SearchWrapper>
        )}
        {isMenuVisible && (
          <>
            <MobileNavMenu closeMenu={closeMenu}>
              <MobileNavigation
                closeMenu={closeMenu}
                items={items}
                user={user}
                cartIsImporting={cartIsImporting}
              />
            </MobileNavMenu>
            <Dimmer height={100} />
          </>
        )}
      </HeaderWrapper>
    </StyledMobileWrapper>
  );
}

export default withRouter(MobileHeader);
