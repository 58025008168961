import * as React from 'react';
import { __ } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { MetaTags, CartDeliveryPart, EmptyCart } from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import {
  fetchDeliveryPaymentInfo,
  cartDelPayInfoIsFetchingSelector,
  updateCart,
  cartDelPayInfoByIdSelector,
  cartDelPayInfoIdsSelector,
} from './cartSlice';

interface Props {
  user: any;
  dispatch: any;
  data: ThenArg<typeof API.getCart> | null;
  isFetching: boolean;
  delAndPayInfoIds: string[];
  delAndPayInfoById: Record<string, ThenArg<typeof API.getDeliveryPaymentInfo>>;
  isFetchingDelAndPayInfo: boolean;
}

class CartThirdStep extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      await dispatch(fetchDeliveryPaymentInfo());

      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  public render() {
    const {
      data,
      isFetching,
      delAndPayInfoIds,
      delAndPayInfoById,
      isFetchingDelAndPayInfo,
      user,
    } = this.props;
    const normalItems =
      data && data.items ? data.items.filter((i: any) => !i.is_demand) : '';

    const isDemand = normalItems.length === 0;

    const isB2B = user && user.b2b;
    const b2cAllowedDeliveries = ['PERSONAL', 'EXTERNAL'];
    const b2cAllowedPayments = ['KARTA', 'PREDFAKTURA', 'DOBIERKA_B2C'];
    const filteredDelAndPayInfoIds = isB2B
      ? delAndPayInfoIds
      : delAndPayInfoIds.filter(d => b2cAllowedDeliveries.includes(d));

    if (!data) {
      return null;
    }

    const { items } = data;

    return (
      <>
        <MetaTags tags={{ title: __('Košík - Doprava a platba') }} />
        {items.length === 0 ? (
          <EmptyCart />
        ) : (
          <CartDeliveryPart
            data={data}
            isFetching={isFetching}
            delAndPayInfoIds={filteredDelAndPayInfoIds}
            delAndPayInfoById={delAndPayInfoById}
            isFetchingDelAndPayInfo={isFetchingDelAndPayInfo}
            updateCart={this.updateCart}
            b2cAllowedPayments={b2cAllowedPayments}
            isB2B={isB2B}
            isDemand={isDemand}
          />
        )}
      </>
    );
  }

  private updateCart = data => {
    this.props.dispatch(updateCart(data, true));
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    delAndPayInfoIds: cartDelPayInfoIdsSelector(state),
    delAndPayInfoById: cartDelPayInfoByIdSelector(state),
    isFetchingDelAndPayInfo: cartDelPayInfoIsFetchingSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CartThirdStep' })(CartThirdStep),
);
