/* eslint-disable */
import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import API, { ThenArg } from '../../services/API';
import { rem } from 'polished';
import {
  FlexCol,
  ZemplinLoaderWrapper,
  FlexRow,
  CategoryHeader,
  FlexRowCenterVertical,
  ViewSwitcher,
  Pagination,
  ZemplinCategoryFilter as CategoryFilter,
  ZemplinFilterType as FilterType,
  ZemplinResetFilter as ResetFilter,
  ZemplinFilterBackButton as FilterBackButton,
  ZemplinMobileCategoryFilterButtons as MobileCategoryFilterButtons,
  MobileNavMenu,
  ZemplinMobileFilterMenu as MobileFilterMenu,
  ZemplinLinksFilter as LinksFilter,
  ZemplinActiveFilters as ActiveFilters,
  ZemplinCategoryTopFilters as CategoryTopFilters,
  ZemplinCategoryFilterCheckboxes as CategoryFilterCheckboxes,
  ZemplinCategoryProductList as CategoryProductList,
  ZemplinCategoryTableList as CategoryTableList,
  NoItemsWrapper,
  ZemplinCategoryCard as CategoryCard,
  CategoryProductsOnPage,
} from 'eshop-defaults';

import { prop, arrayToObject } from '../../utilities';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import InfoBanner from '../../components/_helpers/Default/InfoBanner';
import { useLocalStorage } from '../../utilities/hooks';
import { getCategoryMetaTags } from '../../utilities/metatags';
import { setAddToCartModalVisibility } from '../../containers/Cart/cartSlice';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../../containers/BreadCrumb/breadCrumbSlice';
import { withRouter, WithRouterProps } from 'react-router';
import {
  urlAttribsToObject,
  attribsObjectToUrl,
  hiddenAttribsByCategory,
} from '../../containers/Category/categorySlice';
import { resolveCategoryUrl } from 'eshop-defaults/lib/utilities/selectors';
import { setCustomerGoodOrderNr } from '../../containers/Product/actions';
import {
  isCategory,
  findCategoryIdFromArray,
} from 'eshop-defaults/lib/components/Zemplin/selectors';
import { getCategoryIdsFromProduct } from '../../utilities/category';
import { getImagePath } from '../../utilities/product';
import { BreadcrumbsMicrodata } from '@bart.sk-ecommerce/react-microdata';
import { getCategoryBreadcrumbMicrodata } from '../../utilities/microdata';
import { resolveLangByHostname } from '../../configureTrans';
import { isSSR } from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';

export interface Props {
  dispatch: any;
  isFetching: boolean;
  // products: ThenArg<typeof API.loadElasticCategoryProducts>;
  products: any;
  data: ThenArg<typeof API.loadCategory>;
  user: any;
  catName: string;
  filterData: {
    minPrice: number;
    maxPrice: number;
    attribs: any;
  };
  childrenCategories: any[];
  cart: ThenArg<typeof API.getCart> | null;
  currentTheme?: string;
}

function Category({
  isFetching,
  products,
  data,
  dispatch,
  user,
  catName,
  filterData,
  location,
  router,
  childrenCategories,
  cart,
  currentTheme,
}: Props & WithRouterProps) {
  const catUrl = prop(location, 'pathname');
  const query = prop(location, 'search');
  const urlAttribs = prop(location, 'query');
  const urlAttribsObj = urlAttribsToObject(urlAttribs);
  let productCount = prop(urlAttribsObj, 'productCount', 12);
  const [yPosition, setYPosition] = React.useState(0);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (window && yPosition > 0 && products && !isFetching) {
        window.scrollTo({ behavior: 'smooth', top: yPosition });
        setYPosition(0);
      }
      return () => {
        clearTimeout(timeout);
      };
    }, 400);
  }, [window, yPosition, products, isFetching]);

  const [currentView, setCurrentView] = useLocalStorage('product-view', 'card');
  const [isCategoryFilterVisible, setIsCategoryFilterVisible] = React.useState(
    false,
  );
  const [isFilterMenuVisible, setIsFilterMenuVisible] = React.useState(false);
  const [isShowingAllCategories, setIsShowingAllCategories] = React.useState(
    false,
  );
  const categoryName = catName ? catName : prop(data, 'category_name');
  const categoryDesc = prop(data, 'category_descr', '') || '';
  const parentCategories = prop(data, 'parent_categories', null);
  const previousCategory = parentCategories
    ? parentCategories[parentCategories.length - 1]
    : null;

  const productCategoryIds = getCategoryIdsFromProduct(data);
  productCategoryIds.push(prop(data, 'category_number', ''));
  const categoryNumber = findCategoryIdFromArray(productCategoryIds);
  const hiddenAttribs = hiddenAttribsByCategory[categoryNumber]
    ? hiddenAttribsByCategory[categoryNumber]
    : [];

  const isSpojOrDrev =
    isCategory(productCategoryIds, 'SPOJ_MATERIAL') ||
    isCategory(productCategoryIds, 'PRE_DREVAROV');

  const categoryLinks = childrenCategories
    .filter(c => c.counts && c.counts > 0)
    .map(c => ({
      name: c.category_name,
      url: resolveCategoryUrl(c.category_id, c.url),
    }));

  const cartItems = prop(cart, 'items', []);
  const cartItemsIds: any = cartItems
    ? cartItems.map(c => prop(c, 'good.good_id'))
    : [];

  React.useEffect(() => {
    dispatch(setBreadCrumbPath(BreadCrumbType.CATEGORY, data));
  }, [data, dispatch]);

  const toggleCategoryFilterMenu = () => {
    document.body.style.overflow = isCategoryFilterVisible
      ? 'visible'
      : 'hidden';
    setIsCategoryFilterVisible(!isCategoryFilterVisible);
  };

  const toggleFilterMenu = () => {
    document.body.style.overflow = isFilterMenuVisible ? 'visible' : 'hidden';
    setIsFilterMenuVisible(!isFilterMenuVisible);
  };

  const handleFilterChange = (
    type: FilterType,
    newData:
      | { min: number; max: number; attribId?: string }
      | {
          attribId: string;
          valueId: string;
          newValue: boolean;
          sameIds?: string[];
        },
  ) => {
    setYPosition(window.pageYOffset);
    switch (type) {
      case FilterType.RANGE: {
        const attribId = prop(newData, 'attribId');
        if (attribId) {
          const currentRangeAttribFilters = prop(urlAttribsObj, 'rangeAttribs');
          const min = parseInt(prop(newData, 'min').toFixed(0));
          const max = parseInt(prop(newData, 'max').toFixed(0));
          const { attribs } = filterData;
          const attribDataMin = prop(attribs[attribId], 'attrib_data.min');
          const attribDataMax = prop(attribs[attribId], 'attrib_data.max');

          let rangeAttribsObj = currentRangeAttribFilters
            ? currentRangeAttribFilters
            : {};

          if (
            Math.floor(attribDataMin) !== min ||
            Math.ceil(attribDataMax) !== max
          ) {
            rangeAttribsObj[attribId] = {
              min: parseInt(prop(newData, 'min').toFixed(0)),
              max: parseInt(prop(newData, 'max').toFixed(0)),
            };
          } else {
            delete rangeAttribsObj[attribId];
          }
          router.push(
            attribsObjectToUrl(catUrl, {
              ...urlAttribsObj,
              rangeAttribs: rangeAttribsObj,
            }),
          );
        } else {
          urlAttribsObj.min = parseInt(prop(newData, 'min').toFixed(0));
          urlAttribsObj.max = parseInt(prop(newData, 'max').toFixed(0));
          router.push(attribsObjectToUrl(catUrl, urlAttribsObj));
        }

        break;
      }

      case FilterType.OPTIONS: {
        const attribId = prop(newData, 'attribId');
        const valueId = prop(newData, 'valueId');
        const newValue = prop(newData, 'newValue');
        const currentAttribFilters = prop(urlAttribsObj, 'attribs');

        let updatedAttribsObj = currentAttribFilters;
        let values = updatedAttribsObj
          ? prop(currentAttribFilters[attribId], 'values')
          : null;
        if (values) {
          if (newValue) {
            values.push(valueId);
          } else {
            values = values.filter(v => valueId.toString() !== v);
          }
          if (values.length === 0) {
            updatedAttribsObj[attribId].values = null;
            delete updatedAttribsObj[attribId];
          } else {
            updatedAttribsObj[attribId].values = values;
          }
        } else {
          updatedAttribsObj = {
            ...updatedAttribsObj,
            [attribId]: { values: [valueId] },
          };
        }

        router.push(
          attribsObjectToUrl(catUrl, {
            ...urlAttribsObj,
            attribs: updatedAttribsObj,
          }),
        );

        break;
      }

      default:
        break;
    }
  };

  const handleSortChange = e => {
    const value = e.target.value;
    const [sort, sortDir] = value.split('_');
    router.push(
      attribsObjectToUrl(catUrl, {
        ...urlAttribsObj,
        sort,
        sortDir: sortDir ? sortDir : null,
      }),
    );
  };

  const handleCheckboxChange = ({ name, value }) => {
    router.push(
      attribsObjectToUrl(catUrl, {
        ...urlAttribsObj,
        otherAttribs: {
          ...urlAttribsObj.otherAttribs,
          [name]: value ? 1 : 0,
        },
      }),
    );
  };

  const handleProductCountChange = e => {
    productCount = e.target.value;
    router.push(
      attribsObjectToUrl(catUrl, {
        ...urlAttribsObj,
        otherAttribs: {
          ...urlAttribsObj.otherAttribs,
          productCount: e.target.value,
        },
      }),
    );
  };

  const { limit, offset, total, products: productsArray } = products || {};
  const { minPrice, maxPrice, attribs } = filterData;

  const renderCustomAttribs = () => {
    return (
      <>
        {attribs &&
          Object.keys(attribs).map(key => {
            const attrib = attribs[key];
            const attribType = prop(attrib, 'attrib_data.attrib_type');
            const attribUnit = prop(attrib, 'attrib_data.attrib_unit');
            const valuesObj = attribs[key].values;
            const filteredAttribs = {};
            const attribKeys: any[] = [];
            Object.keys(valuesObj).map(key => {
              const value = prop(valuesObj[key], 'info.attrib_value');
              if (!value || value === '' || value === '-') {
              } else {
                filteredAttribs[key] = valuesObj[key];
              }

              const attribId = prop(valuesObj[key], 'info.attrib_id');
              if (!attribKeys.includes(attribId)) {
                attribKeys.push(attribId);
              }
            });

            const hasValues = Object.keys(filteredAttribs).length > 0;
            if (hasValues) {
              const showFilter = Object.keys(filteredAttribs).findIndex(
                valueKey => filteredAttribs[valueKey].doc_count > 0,
              );
              const activeValues: any[] = [];
              attribKeys.map(attrId => {
                activeValues.push(
                  ...prop(
                    prop(urlAttribsObj, 'attribs', {})[attrId],
                    'values',
                    [],
                  ),
                );
              });
              const rangeActiveValues = {};
              attribKeys.map(attrId => {
                const value = prop(urlAttribsObj, 'rangeAttribs', {})[attrId];
                if (value) {
                  rangeActiveValues[attrId] = value;
                }
              });
              const rangeActive =
                rangeActiveValues[prop(attrib, 'attrib_data.attrib_id')];
              const hasActive = rangeActive || activeValues.length > 0;

              const attribName = prop(
                attrib,
                'attrib_data.attrib_name',
                `Attrib ${key}`,
              );

              if (
                (showFilter !== -1 || hasActive) &&
                !hiddenAttribs.includes(attribName)
              ) {
                if (attribType === 'rozsah') {
                  return (
                    <CategoryFilter
                      key={key}
                      name={attribName}
                      type={FilterType.RANGE}
                      filterData={{
                        activeMin: prop(
                          rangeActive,
                          'min',
                          prop(attrib, 'attrib_data.min', 0),
                        ),
                        activeMax: prop(
                          rangeActive,
                          'max',
                          prop(attrib, 'attrib_data.max', 0),
                        ),
                        min: +prop(attrib, 'attrib_data.min'),
                        max: +prop(attrib, 'attrib_data.max'),
                        attribId: prop(attrib, 'attrib_data.attrib_id'),
                      }}
                      handleFilterChange={handleFilterChange}
                      activeValues={rangeActiveValues}
                      initialIsOpen={rangeActive}
                      unit={attribUnit}
                    />
                  );
                }

                return (
                  <CategoryFilter
                    key={key}
                    name={attribName}
                    type={FilterType.OPTIONS}
                    filterData={{
                      values: prop(attrib, 'values'),
                      attribId: key,
                    }}
                    handleFilterChange={handleFilterChange}
                    activeValues={activeValues}
                    initialIsOpen={hasActive}
                  />
                );
              }
            }
            return null;
          })}
      </>
    );
  };

  const renderLeftSide = () => {
    if (!attribs) {
      return null;
    }
    return (
      <LeftSideWrapper>
        {previousCategory && (
          <FilterBackButton
            name={previousCategory.category_name}
            url={resolveCategoryUrl(
              previousCategory.category_id,
              previousCategory.url,
            )}
          />
        )}
        {categoryLinks && categoryLinks.length > 0 && (
          <CategoryFilter
            name={'Kategórie'}
            type={FilterType.LINKS}
            initialIsOpen={true}
            filterData={{ links: categoryLinks }}
            handleFilterChange={handleFilterChange}
          />
        )}
        {!isSpojOrDrev && (
          <CategoryFilter
            name={'Cena'}
            type={FilterType.RANGE}
            initialIsOpen={true}
            filterData={{
              min: minPrice,
              max: maxPrice,
              activeMin: prop(urlAttribs, 'min'),
              activeMax: prop(urlAttribs, 'max'),
            }}
            handleFilterChange={handleFilterChange}
            unit={'€'}
          />
        )}
        {renderCustomAttribs()}
        <ResetFilter url={catUrl} name={'Resetovať'} />
      </LeftSideWrapper>
    );
  };

  const renderCategoriesFilter = () => {
    return (
      <MobileCategoryFilter>
        {categoryLinks && categoryLinks.length > 0 ? (
          <LinksFilter
            isOpen={true}
            isMobile={true}
            filterData={{ links: categoryLinks }}
          />
        ) : (
          <NoItemsWrapper
            style={{ marginLeft: '16px' }}
            height={100}
            text={__('Nenašli sa žiadne ďalšie podkategórie')}
          />
        )}
      </MobileCategoryFilter>
    );
  };

  const renderFilterCheckboxes = () => {
    return (
      <CategoryFilterCheckboxes
        filters={prop(urlAttribsObj, 'otherAttribs')}
        handleCheckboxChange={handleCheckboxChange}
        isB2B={user && user.b2b}
      />
    );
  };

  const renderOtherFilters = () => {
    return (
      <MobileCategoryFilter>
        {renderFilterCheckboxes()}
        {!isSpojOrDrev && (
          <CategoryFilter
            name={'Cena'}
            type={FilterType.RANGE}
            initialIsOpen={true}
            filterData={{
              min: minPrice,
              max: maxPrice,
              activeMin: prop(urlAttribs, 'min'),
              activeMax: prop(urlAttribs, 'max'),
            }}
            handleFilterChange={handleFilterChange}
            unit={'€'}
          />
        )}
        {renderCustomAttribs()}
        <ResetFilter name={'Resetovať'} url={catUrl} />
      </MobileCategoryFilter>
    );
  };

  const renderRightSide = () => {
    if (isFetching || !attribs) {
      return <ZemplinLoaderWrapper height={200} />;
    }
    const isCardView = currentView === 'card';

    const finalChildrenCategories = isShowingAllCategories
      ? childrenCategories
      : childrenCategories.slice(0, 4);

    const sort = prop(urlAttribs, 'sort');
    const sortDir = prop(urlAttribs, 'sortDir');

    const sortValue = sort && sortDir ? `${sort}_${sortDir}` : 'none';
    const activeAttribs = JSON.parse(
      JSON.stringify(prop(urlAttribsObj, 'attribs', {})),
    );
    Object.keys(activeAttribs).map(key => {
      const activeValues = activeAttribs[key].values;
      const valuesObj = arrayToObject(attribs[key].values, 'attrib_value');
      const newValues: any[] = [];
      activeValues.map(value => {
        newValues.push({
          valueId: value,
          name: prop(valuesObj, `${value}.info.attrib_value`, value),
          attribName: prop(attribs, `${key}.attrib_data.attrib_name`),
        });
      });
      activeAttribs[key].values = newValues;
    });

    const placeholderImgSrc = '/images/zemplin/placeholder.svg';
    const numberOfProductsOnPage = [
      { value: '12', name: '12' },
      { value: '24', name: '24' },
      { value: '48', name: '48' },
    ];
    return (
      <RightSideWrapper>
        <>
          <CategoryHeader title={categoryName} desc={categoryDesc} />
          {prop(data, 'content.0.json_content.body', null) ? (
            <CategoryDesc
              dangerouslySetInnerHTML={{
                __html: prop(data, 'content.0.json_content.body', ''),
              }}
            />
          ) : null}
        </>

        {isFetching ? (
          <ZemplinLoaderWrapper height={500} />
        ) : !productsArray ? null : (
          <>
            <SortWrapper>
              <CategoryTopFilters
                handleChange={handleSortChange}
                sortValue={sortValue}
              >
                <DesktopCheckboxesWrapper>
                  {renderFilterCheckboxes()}
                </DesktopCheckboxesWrapper>
              </CategoryTopFilters>
              <ViewSwitcher changeView={changeView} currentView={currentView} />
              <CategoryProductsOnPage
                handleChange={handleProductCountChange}
                displayNumber={productCount}
                text={'Zobraziť produkty: '}
                customOptions={numberOfProductsOnPage}
              />
            </SortWrapper>
            {(!currentTheme || currentTheme !== 'heatCategory') && (
              <InfoTextWrapper>
                <InfoTextSpan>
                  {__('Používaná merná jednotka Mks = 1000 ks')}
                </InfoTextSpan>
              </InfoTextWrapper>
            )}
            <MobileFiltersWrapper>
              {/* <MobileCategoryFilterButtons
                onClick={toggleCategoryFilterMenu}
                text={__('Kategórie')}
              /> */}
              <MobileCategoryFilterButtons
                onClick={toggleFilterMenu}
                text={__('Filter')}
              />
            </MobileFiltersWrapper>
            {// window.innerWidth > 500 &&
            finalChildrenCategories && finalChildrenCategories.length > 0 && (
              <ChildrenCategoryCards>
                {finalChildrenCategories.map(c => (
                  <React.Fragment key={c.category_id}>
                    {c.counts > 0 ? (
                      <CategoryCard
                        name={c.category_name}
                        url={resolveCategoryUrl(c.category_id, c.url)}
                        img={
                          c.image
                            ? getImagePath(
                                c.image,
                                {
                                  width: 180,
                                  height: 180,
                                },
                                false,
                                false,
                                false,
                              )
                            : placeholderImgSrc
                        }
                      />
                    ) : null}
                  </React.Fragment>
                ))}
                {childrenCategories.length > 4 && (
                  <ShowAllCategoriesButton
                    onClick={() => setIsShowingAllCategories(o => !o)}
                  >
                    {isShowingAllCategories
                      ? __('Skryť viac kategórií')
                      : __('Zobraziť viac kategórií')}
                  </ShowAllCategoriesButton>
                )}
              </ChildrenCategoryCards>
            )}
            {childrenCategories.length > 4 && (
              <ShowAllCategoriesButtonMobile
                onClick={() => setIsShowingAllCategories(o => !o)}
              >
                {isShowingAllCategories
                  ? __('Skryť viac kategórií')
                  : __('Zobraziť viac kategórií')}
              </ShowAllCategoriesButtonMobile>
            )}
            {activeAttribs && Object.keys(activeAttribs).length > 0 && (
              <ActiveFiltersWrapper>
                <ActiveFilters
                  resetUrl={catUrl}
                  activeAttribs={activeAttribs}
                  onClickAttrib={handleFilterChange}
                />
              </ActiveFiltersWrapper>
            )}
            <br />
            {renderProductsCardView(isCardView)}
            {renderProductsListView(isCardView)}
            <Pagination
              query={`${catUrl}${`${query}` ? query : '?'}`}
              totalItems={total}
              limit={limit}
              offset={offset}
            />
          </>
        )}
      </RightSideWrapper>
    );
  };

  const renderProductsCardView = (isCardView: boolean) => {
    return (
      <CategoryProductList
        isFetching={isFetching}
        products={productsArray}
        addToCart={addToCart}
        addToRequest={addToRequest}
        visible={isCardView}
        user={user}
        handleOrderNrChange={handleOrderNrChange}
        showPackage={isSpojOrDrev}
        showPriceIfNotOnStock={!isSpojOrDrev}
        cartItemsIds={cartItemsIds}
      />
    );
  };

  const renderProductsListView = (isCardView: boolean) => {
    return (
      <CategoryTableList
        isFetching={isFetching}
        products={productsArray}
        addToCart={addToCart}
        addToRequest={addToRequest}
        visible={!isCardView}
        user={user}
        handleOrderNrChange={handleOrderNrChange}
        showPackage={isSpojOrDrev}
        showPriceIfNotOnStock={!isSpojOrDrev}
        cartItemsIds={cartItemsIds}
      />
    );
  };

  const changeView = () => {
    setCurrentView(currentView === 'card' ? 'list' : 'card');
  };

  const addToCart = (product: any) => {
    dispatch(setAddToCartModalVisibility(false, product, true));
  };

  const addToRequest = (product: any) => {
    dispatch(setAddToCartModalVisibility(true, product, true));
  };

  const handleOrderNrChange = (goodId, value) => {
    dispatch(setCustomerGoodOrderNr(goodId, value));
  };

  return (
    <>
      <BreadcrumbsMicrodata
        items={getCategoryBreadcrumbMicrodata(
          data,
          isSSR() ? 'sk' : resolveLangByHostname(window.location.hostname),
        )}
      />
      <Wrapper className="container container--wide">
        <MetaTags
          canonicalUrl={`${process.env.REACT_APP_BASE_URL}/${prop(
            data,
            'publish.0.category_url',
          ) || prop(data, 'url')}`}
          tags={getCategoryMetaTags(data)}
        />
        {renderLeftSide()}
        {renderRightSide()}
      </Wrapper>
      {isCategoryFilterVisible && (
        <>
          <MobileNavMenu closeMenu={toggleCategoryFilterMenu}>
            <MobileFilterMenu
              title={__('Kategórie')}
              handleClose={toggleCategoryFilterMenu}
            >
              {renderCategoriesFilter()}
            </MobileFilterMenu>
          </MobileNavMenu>
          <Dimmer height={100} />
        </>
      )}
      {isFilterMenuVisible && (
        <>
          <MobileNavMenu closeMenu={toggleFilterMenu}>
            <MobileFilterMenu
              title={__('Filter')}
              handleClose={toggleFilterMenu}
            >
              {renderOtherFilters()}
            </MobileFilterMenu>
          </MobileNavMenu>
          <Dimmer height={100} />
        </>
      )}
      <InfoBanner />
    </>
  );
}

const Wrapper = styled(FlexRow)`
  width: 100%;
  padding-top: ${rem(56)};
  margin-bottom: ${rem(80)} !important;

  // @media only screen and (max-width: 1740px) {
  //   max-width: ${rem(1080)};
  //   padding-top: ${rem(32)};
  //   margin-bottom: ${rem(32)} !important;
  // }
  //
  // @media only screen and (max-width: 1300px) {
  //   max-width: ${rem(960)};
  //   padding-top: ${rem(32)};
  //   margin-bottom: ${rem(32)} !important;
  // }

  ${({ theme }) => theme.mediab.l1050`
      padding-top: ${rem(32)};
      margin-bottom: ${rem(32)} !important;
  `}
`;

const MobileCategoryFilter = styled(FlexCol)`
  width: 100%;
`;

const RightSideWrapper = styled(FlexCol)`
  /* padding: ${rem(24)}; */
  max-width: ${rem(1048)};
  width: 100%;
`;

const LeftSideWrapper = styled(FlexCol)`
  max-width: ${rem(304)};
  margin-right: ${rem(56)};
  width: 100%;

  @media only screen and (max-width: 1740px) {
    margin-right: ${rem(26)};
    width: max-content;
    min-width: 300px;
  }

  @media only screen and (max-width: 720px) {
    display: none;
  }
`;

export const SortWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(8)};
  justify-content: space-between;
  gap: ${rem(8)};

  @media only screen and (max-width: 900px) {
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 1740px) {
    margin-bottom: ${rem(24)};
  }
`;

const MobileFiltersWrapper = styled(FlexRowCenterVertical)`
  display: none;
  justify-content: space-between;
  max-width: ${rem(320)};
  margin: 0 -${rem(16)};

  @media only screen and (max-width: 800px) {
    display: flex;
  }

  ${({ theme }) => theme.mediab.s450`
      max-width: ${rem(450)};
      box-shadow: ${({ theme }) => theme.boxShadow.default};
      padding: 0 ${rem(8)};
  `}
`;

const ActiveFiltersWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(56)};

  @media only screen and (max-width: 1740px) {
    margin-top: ${rem(32)};
    margin-bottom: ${rem(32)};
  }
`;

const DesktopCheckboxesWrapper = styled.div`
  ${({ theme }) => theme.mediab.m760`
   display: none;
  `}
`;

const InfoTextWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(32)};
  padding-left: ${rem(8)};
`;

const InfoTextSpan = styled.span`
  font-size: ${rem(14)};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const CategoryDesc = styled.div`
  color: ${({ theme }) => theme.colors.textPrimary};
`;

export const ChildrenCategoryCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: ${rem(8)} ${rem(16)};

  ${({ theme }) => theme.mediab.l1150`
   margin-top: ${rem(24)};
  `}

  @media only screen and (max-width: 720px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
`;

export const ShowAllCategoriesButton = styled.button`
  border: 0;
  background-color: white;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
  padding: 0;
  cursor: pointer;
  font-size: ${rem(18)};
  text-align: center;
  max-height: ${rem(182)};
  border: ${({ theme }) => theme.borders.primary};
  border-radius: ${rem(4)};
  box-shadow: ${({ theme }) => theme.boxShadow.default};
  padding: ${rem(16)};
  margin-bottom: ${rem(16)};

  &:hover {
    text-decoration: none;
  }

  @media only screen and (max-width: 720px) {
    display: none;
  }
`;

export const ShowAllCategoriesButtonMobile = styled(ShowAllCategoriesButton)`
  display: none;

  @media only screen and (max-width: 720px) {
    padding: ${rem(16)};
    display: block;
    margin-top: ${rem(16)};
    font-size: ${rem(16)};
  }
`;

export default withRouter(Category);
