import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import API, { ThenArg } from '../../services/API';
import {
  loadHomeNewProducts,
  loadHomeSaleProducts,
  loadHomeBenefits,
  loadHomeBanners,
} from './actions';
import { langSelector, titleSelector } from '../Category/selectors';
import {
  saleProductsSelector,
  newProductsSelector,
  firstHomeBenefitsSelector,
  secondHomeBenefitsSelector,
  homeBannersSelector,
} from './selectors';
import Home from '../../components/Home/Home';
import {
  productCategoriesSelector,
  productCategoriesIsFetchingSelector,
  homeDimmerSelector,
  firstCategoryIdSelector,
} from '../Header/selectors';
import { loadCategoriesTree } from '../Header/actions';
import {
  setAddToCartModalVisibility,
  cartDataSelector,
} from '../Cart/cartSlice';

export const MainWrapper = styled.div`
  padding: ${rem(30)} 0 0;
  width: 100%;

  ${({ theme }) => theme.mediab.m810`
     padding-top: ${rem(16)};
  `}
`;

export const FillSpaceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
`;

interface Props {
  parentProps: {};
  dispatch: any;
  isError: boolean;
  loaded: any;
  defaultTitle: string;
  lang: string;
  modal: any;
  adBanner: any;
  router: any;
  location: any;
  banners: ThenArg<typeof API.loadBanners>['banners'];
  newProducts: {
    productsData: ThenArg<typeof API.searchProducts>;
    isFetching: false;
  };
  saleProducts: {
    productsData: ThenArg<typeof API.searchProducts>;
    isFetching: false;
  };
  productCategories: ThenArg<typeof API.loadTree>;
  productCategoriesIsFetching: boolean;
  dimmerVisible: boolean;
  firstCategoryId: number;
  user: any;
  cart: any;
  firstBenefits: any[];
  secondBenefits: any[];
}

class HomeContainer extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      const firstCategoryId = await dispatch(loadCategoriesTree());
      await Promise.all[
        (await dispatch(loadHomeNewProducts(firstCategoryId)),
        await dispatch(loadHomeBanners()),
        await dispatch(loadHomeSaleProducts(firstCategoryId)),
        await dispatch(loadHomeBenefits()))
      ];

      return {
        props,
        isLoaded: true,
      };
    } catch (exp) {
      console.log(exp);
      return {
        isError: true,
        isLoaded: true,
      };
    }
  }

  public render() {
    const {
      productCategories,
      productCategoriesIsFetching,
      saleProducts,
      newProducts,
      dimmerVisible,
      dispatch,
      firstCategoryId,
      user,
      cart,
      firstBenefits,
      secondBenefits,
      banners,
    } = this.props;

    return (
      <Home
        banners={banners}
        categories={productCategories}
        addToCart={this.addToCart}
        addToRequest={this.addToRequest}
        productCategoriesIsFetching={productCategoriesIsFetching}
        newProducts={newProducts}
        saleProducts={saleProducts}
        dimmerVisible={dimmerVisible}
        dispatch={dispatch}
        firstCategoryId={firstCategoryId}
        user={user}
        cart={cart}
        firstBenefits={firstBenefits}
        secondBenefits={secondBenefits}
      />
    );
  }

  private addToCart = (product: any) => {
    this.props.dispatch(setAddToCartModalVisibility(false, product));
  };

  private addToRequest = (product: any) => {
    this.props.dispatch(setAddToCartModalVisibility(true, product));
  };
}

const mapStateToProps = state => {
  return {
    defaultTitle: titleSelector(state),
    lang: langSelector(state),
    newProducts: newProductsSelector(state),
    banners: homeBannersSelector(state),
    saleProducts: saleProductsSelector(state),
    productCategories: productCategoriesSelector(state),
    productCategoriesIsFetching: productCategoriesIsFetchingSelector(state),
    dimmerVisible: homeDimmerSelector(state),
    firstCategoryId: firstCategoryIdSelector(state),
    user: state.auth.user,
    cart: cartDataSelector(state),
    firstBenefits: firstHomeBenefitsSelector(state),
    secondBenefits: secondHomeBenefitsSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'HomeContainer' })(HomeContainer),
);
