import * as React from 'react';
import { __ } from 'react-i18n';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  LeftUpper,
  FlexRow,
  RightUpper,
  getProductDetailInfo,
  ProductTitleImage,
  MobileLeftUpper,
  ZemplinLoaderWrapper,
  BottomInfo,
  InfoText,
  getZemplinProductDefaultInfo,
  ImageGallery,
  FlexRowCenterVertical,
  FlexColCenter,
  prop,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { getProductMetaTags } from '../../utilities/metatags';
import InfoBanner from '../_helpers/Default/InfoBanner';
import ShareWrapper from '../_helpers/Default/ShareWrapper';
import { stripHtmlTags } from '../../utilities';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import SecondSlider from '../_helpers/Slider/SecondSlider';
import {
  addItemToCart,
  setAddToCartModalVisibility,
} from '../../containers/Cart/cartSlice';
import {
  getZemplinTrueCount,
  shouldShowPriceIfNotOnStock,
  isCategory,
  isSpojMatOrPreDrev,
  findCategoryIdFromArray,
  resolveIsVykurovaciaOrPletiva,
} from 'eshop-defaults/lib/components/Zemplin/selectors';
import { getProductDefaultInfo } from 'eshop-defaults/lib/utilities/selectors';
import { setCustomerGoodOrderNr } from '../../containers/Product/actions';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../../containers/BreadCrumb/breadCrumbSlice';
import { getCategoryIdsFromProduct } from '../../utilities/category';
import { getImagePath, decodeHtml } from '../../utilities/product';
import { ModalMobile } from '../_helpers/Default/ModalMobile';
import Modal from '../_helpers/Default/Modal';
import { WithRouterProps, withRouter } from 'react-router';
import { hiddenAttribsInSpecsByCategory } from '../../containers/Category/categorySlice';
import * as cookies from 'react-cookies';
import { LANG_COOKIE } from '../../containers/App/constants';
import { round } from '../../utilities';
import { contactPersonsSelector } from 'src/containers/Contact/contactSlice';
import MetaTags from '../_helpers/MetaTags/MetaTags';
import {
  ProductMicrodata,
  BreadcrumbsMicrodata,
} from '@bart.sk-ecommerce/react-microdata';

interface Props {
  user: ThenArg<typeof API.tokeninfo>;
  lang?: string;
  product: ThenArg<typeof API.loadProduct>;
  isFetching: boolean;
  alternativeProducts: ThenArg<typeof API.loadProductConnections>;
  isFetchingAlternatives: boolean;
  accessoriesProducts: ThenArg<typeof API.loadProductConnections>;
  isFetchingAccessories: boolean;
  dispatch: any;
  cart: ThenArg<typeof API.getCart> | null;
}

function Product({
  product,
  isFetching,
  alternativeProducts,
  isFetchingAlternatives,
  accessoriesProducts,
  isFetchingAccessories,
  dispatch,
  user,
  location,
  router,
  cart,
}: Props & WithRouterProps) {
  const bottomRef: any = React.useRef() as React.MutableRefObject<
    HTMLInputElement
  >;
  const relatedRef: any = React.useRef() as React.MutableRefObject<
    HTMLInputElement
  >;
  const accessoriesRef: any = React.useRef() as React.MutableRefObject<
    HTMLInputElement
  >;

  React.useEffect(() => {
    dispatch(setBreadCrumbPath(BreadCrumbType.PRODUCT, product));
  }, [product, dispatch]);

  // React.useEffect(() => {
  //   const { pathname } = location;
  //   const url = prop(product, 'url');
  //   const id = prop(product, 'product_id');
  //   const queryProductId = prop(router, 'params.product_id');
  //   if (
  //     id &&
  //     queryProductId === id.toString() &&
  //     url &&
  //     pathname !== `/produkt/${id}/${url}`
  //   ) {
  //     router.replace(`/produkt/${id}/${url}`);
  //   }
  // }, [location, product, router]);

  const cartItems = prop(cart, 'items', []);
  const cartItemsIds = cartItems
    ? cartItems.map(c => prop(c, 'good.good_id'))
    : [];

  const [imageModalVisible, setImageModalVisible] = React.useState(false);
  const [modalCurrentImage, setModalCurrentImage] = React.useState(0);

  const { goodId, photogallery } = getProductDefaultInfo(product);

  const isInCart = cartItemsIds.includes(goodId);

  const info = getProductDetailInfo(product);
  const {
    name,
    image,
    ordernr,
    annotation,
    currency,
    issaleout,
    isnew,
    issale,
    picture,
    isSaleB2b,
    description,
  } = info;

  const metaDesc = prop(
    product,
    'publish.0.content.json_content.meta.description',
  );
  const str = annotation ? annotation.replace(/(<([^>]+)>)/gi, '') : '';
  const decodedStr = metaDesc ? metaDesc : str ? str : '';

  const fullscreenImages =
    photogallery || picture
      ? [picture, ...photogallery]
          .filter(a => a)
          .map(doc => {
            const img = getImagePath(doc, {
              width: 800,
              height: 600,
            });
            const thumbImg = getImagePath(doc, {
              width: 87,
              height: 104,
            });
            // replace - Zemplin hack
            return {
              original: img.replace('product/files', 'media'),
              thumbnail: thumbImg.replace('product/files', 'media'),
              name: '',
            };
          })
      : [];
  const {
    unit,
    price,
    priceWithoutVat,
    assembling,
    productPackages,
    onStock,
    orderNr,
    mocPrice,
    b2bDiscount,
    unpackingCost,
    unpackingCostWithoutVat,
    storesInfo,
    statusB2C,
    statusB2B,
    specialDiscountPcn,
    specialDiscount,
    specialDiscountWithoutVat,
    customerDiscountPcn,
    freeDelivery,
    priceForUnpacking,
    customerPrices,
    oldPrice,
    onStockExternal,
    isSpojMat,
    defaultPackageSize,
    wholePackagesOnly,
    qtyDiscounts,
    showServis,
    showServisMax,
    boxQuantity,
  } = getZemplinProductDefaultInfo(product);
  const productCategoryIds = getCategoryIdsFromProduct(product);
  const categoryNumber = findCategoryIdFromArray(productCategoryIds);
  const hiddenAttribs = hiddenAttribsInSpecsByCategory[categoryNumber]
    ? hiddenAttribsInSpecsByCategory[categoryNumber]
    : [];

  const isMks = unit === 'Mks';

  // Parameter default_package_size pouzivame ako defaultny ukazovatel velkosti balenia
  const initialCount = defaultPackageSize
    ? defaultPackageSize
    : productPackages
    ? productPackages
    : 1;

  const correctInitialCount =
    initialCount > onStock && onStock > 0 ? onStock : initialCount;

  const noHandlingFee: boolean = user
    ? user.no_handling_fee
      ? user.no_handling_fee === 0
        ? false
        : true
      : false
    : false;

  const [count, setCount] = React.useState(correctInitialCount.toString());

  React.useEffect(() => {
    const initialCount = defaultPackageSize
      ? defaultPackageSize
      : productPackages
      ? productPackages
      : 1;

    const correctInitialCount =
      initialCount > onStock && onStock > 0 ? onStock : initialCount;
    setCount(correctInitialCount);
  }, [unit, isMks, productPackages, onStock, defaultPackageSize]);

  const handleCustomOrderNrBlurChange = e => {
    dispatch(setCustomerGoodOrderNr(goodId, e.target.value));
  };

  const isB2b = user && user.b2b;

  const statusShowPrice = isB2b ? statusB2B !== 3 : statusB2C !== 3;
  const statusShowAddToCart = isB2b ? statusB2B === 0 : statusB2C === 0;
  const showPriceIfNotOnStock = shouldShowPriceIfNotOnStock(productCategoryIds);
  const showPrice = statusShowPrice
    ? showPriceIfNotOnStock
      ? true
      : onStock > 0
    : false;
  const correctShownPrice = showPrice
    ? isB2b
      ? priceWithoutVat
      : price
    : null;

  const isSpojOrDrev =
    isCategory(productCategoryIds, 'SPOJ_MATERIAL') ||
    isCategory(productCategoryIds, 'PRE_DREVAROV');
  const belongsToCentVyk = isCategory(productCategoryIds, 'CENTRUM_VYKUROV');

  const correctShownMocPrice =
    belongsToCentVyk && user && user.b2b ? mocPrice : '';
  const correctShownDiscount = customerDiscountPcn;

  const alterSpecialDiscount =
    issaleout && !specialDiscount
      ? parseFloat(price) - parseFloat(oldPrice)
      : 0;
  const abs = Math.abs(alterSpecialDiscount);

  const alterSpecialDiscountWithoutVat = -round((abs / 100) * 80, 2);

  const correctSpecialDiscount = specialDiscount
    ? specialDiscount
    : alterSpecialDiscount;

  const correctSpecialDiscountWithouVat = specialDiscountWithoutVat
    ? specialDiscountWithoutVat
    : alterSpecialDiscountWithoutVat;

  const correctShownSpecialDiscount =
    specialDiscountPcn && specialDiscountPcn !== 0
      ? specialDiscountPcn
      : isB2b
      ? correctSpecialDiscountWithouVat
      : correctSpecialDiscount;

  const correctShownSpecialDiscountText =
    correctShownSpecialDiscount && correctShownSpecialDiscount !== 0
      ? specialDiscountPcn && specialDiscountPcn
        ? `-${correctShownSpecialDiscount}%`
        : `${`${correctShownSpecialDiscount > 0 ? '-' : ''}${round(
            correctShownSpecialDiscount,
            0,
          )}`} ${currency}${unit ? `/${unit}` : ''}`
      : '';

  let oldPriceCorrect =
    onStock > 0 || !isSpojMat ? (isB2b ? +priceWithoutVat : +price) : 0;

  // Count handler
  const handleCountChange = (newCount: string) => {
    setCount(newCount);
  };

  // Ak nie su uplatnene ziadne zlavy a akie, pri B2B zobrazime povodno cenu pred odratanim 2% vernostnej zlavy
  const oldPriceWithB2bCustomerDiscount = isB2b
    ? (oldPriceCorrect * count) / ((100 - 2) / 100)
    : 0;

  oldPriceCorrect = correctShownSpecialDiscountText.includes('%')
    ? oldPriceCorrect / ((100 - correctShownSpecialDiscount) / 100)
    : correctShownSpecialDiscountText.includes(currency)
    ? oldPriceCorrect - +correctShownSpecialDiscount
    : oldPriceWithB2bCustomerDiscount;

  const renderUpperPart = () => {
    return (
      <>
        <ProductMicrodata
          name={name ? name : ''}
          image={
            picture &&
            getImagePath(picture, {
              width: 300,
              height: 300,
            })
          }
          gtin={ordernr ? ordernr : ''}
          description={decodedStr ? decodedStr : ''}
          offers={
            price && parseFloat(price) > 0
              ? {
                  availability: onStock || 'OutOfStock',
                  price: round(price, 2),
                  priceCurrency: currency ? currency : '€',
                }
              : undefined
          }
        />
        <UpperDesktopWrapper>
          <LeftUpper
            freeDelivery={freeDelivery}
            issaleout={issaleout}
            isnew={isnew}
            issale={isB2b ? isSaleB2b : issale}
            title={name}
            image={image}
            desc={annotation}
            plu={ordernr}
            initialUserOrderNr={orderNr}
            handleCustomOrderNrBlurChange={handleCustomOrderNrBlurChange}
            bottomRef={bottomRef}
            specialDiscount={correctShownSpecialDiscountText}
            isLoggedIn={user && user.b2b ? true : false}
            isInCart={isInCart}
            shareButton={
              <ShareWrapper
                title={name}
                desc={stripHtmlTags(annotation)}
                withTitle={false}
                image={image}
              />
            }
            openModal={() => openModal(0)}
            showFullImage={true}
          >
            <GalleryDesktopWrapper>
              {renderProductImages()}
            </GalleryDesktopWrapper>
          </LeftUpper>
          <RightUpper
            user={user}
            currency={currency}
            onStock={onStock}
            unit={unit}
            inPackage={productPackages}
            defaultPackageSize={defaultPackageSize}
            wholePackagesOnly={wholePackagesOnly}
            price={correctShownPrice}
            showPrice={showPrice}
            addToRequest={addToRequestWithoutModal}
            addToCart={addToCartWithoutModal}
            count={count}
            handleCountChange={handleCountChange}
            sale={correctShownDiscount}
            mocPrice={correctShownMocPrice}
            b2bDiscount={b2bDiscount!}
            unpackingCost={isB2b ? unpackingCostWithoutVat : unpackingCost}
            storesInfo={storesInfo}
            statusShowAddToCart={statusShowAddToCart}
            priceForUnpacking={priceForUnpacking}
            isSpojMatOrPreDrev={isSpojMatOrPreDrev(product)}
            isVykurovaciaOrPletiva={resolveIsVykurovaciaOrPletiva(product)}
            customerPrices={customerPrices}
            isInCart={isInCart}
            onStockExternal={onStockExternal}
            oldPriceCorrect={oldPriceCorrect}
            no_handling_fee={noHandlingFee}
            qtyDiscounts={qtyDiscounts}
            boxQuantity={boxQuantity}
          />
        </UpperDesktopWrapper>
        <UpperMobileWrapper>
          <ImageWrapper>
            <ProductTitleImage
              issaleout={issaleout}
              isnew={isnew}
              title={name}
              image={image}
              issale={isB2b ? isSaleB2b : issale}
              specialDiscount={correctShownSpecialDiscountText}
              isInCart={isInCart}
              showFullImage={true}
            />
          </ImageWrapper>
          {renderProductImages()}
          <RightUpper
            wholePackagesOnly={wholePackagesOnly}
            defaultPackageSize={defaultPackageSize}
            user={user}
            currency={currency}
            onStock={onStock}
            unit={unit}
            showPrice={showPrice}
            inPackage={productPackages}
            price={correctShownPrice}
            addToRequest={addToRequestWithoutModal}
            addToCart={addToCartWithoutModal}
            count={count}
            handleCountChange={handleCountChange}
            sale={correctShownDiscount}
            mocPrice={correctShownMocPrice}
            b2bDiscount={b2bDiscount!}
            unpackingCost={isB2b ? unpackingCostWithoutVat : unpackingCost}
            storesInfo={storesInfo}
            statusShowAddToCart={statusShowAddToCart}
            isSpojMatOrPreDrev={isSpojMatOrPreDrev(product)}
            isVykurovaciaOrPletiva={resolveIsVykurovaciaOrPletiva(product)}
            priceForUnpacking={priceForUnpacking}
            customerPrices={customerPrices}
            isInCart={isInCart}
            onStockExternal={onStockExternal}
            isMobile={true}
            oldPriceCorrect={oldPriceCorrect}
            no_handling_fee={noHandlingFee}
            qtyDiscounts={qtyDiscounts}
            boxQuantity={boxQuantity}
          />
          <MobileLeftUpper
            plu={ordernr}
            initialUserOrderNr={orderNr}
            handleCustomOrderNrBlurChange={handleCustomOrderNrBlurChange}
            shareButton={
              <ShareWrapper
                title={name}
                desc={stripHtmlTags(annotation)}
                withTitle={false}
                image={image}
              />
            }
          />
        </UpperMobileWrapper>
      </>
    );
  };

  const renderProductImages = () => {
    return (
      <GalleryWrapper>
        {fullscreenImages &&
          fullscreenImages.map((image, i) => {
            if (i > 0 && i < 3) {
              return (
                <Thumb
                  src={image.thumbnail}
                  alt={`Obrázok`}
                  onClick={() => openModal(i)}
                />
              );
            }
            if (i === 3) {
              return <MoreThumb onClick={() => openModal(i)}>...</MoreThumb>;
            }
            return null;
          })}
      </GalleryWrapper>
    );
  };

  const renderBottomPart = () => {
    const { description, attribs, downloads } = info;
    const filteredAttribs = attribs.filter(
      a =>
        a.values &&
        a.values.length > 0 &&
        !hiddenAttribs.includes(a.attrib_name),
    );
    return (
      <div ref={bottomRef}>
        <BottomInfo
          accessoriesRef={accessoriesRef}
          relatedRef={relatedRef}
          desc={description}
          attribs={filteredAttribs}
          downloads={downloads}
          showAssembling={assembling}
          assemblingLink={'/o-nakupe/montaz-krbov-a-kachli'}
          servisInfo={{
            showServis,
            servisLink: '/clanky-centrum-vykurovania/67309',
            showServisMax,
            servisMaxLink: '/aktuality/spojovaci-material/65699',
          }}
        />
      </div>
    );
  };

  const renderRelated = () => {
    return (
      <>
        {!isFetchingAlternatives ? (
          alternativeProducts &&
          alternativeProducts.products &&
          alternativeProducts.products.length > 0 && (
            <div ref={relatedRef}>
              <SliderWrapper>
                <Title>{__('Podobné produkty')}</Title>
                <SecondSlider
                  user={user}
                  type="product"
                  slides={alternativeProducts.products}
                  addToCart={addToCart}
                  addToRequest={addToRequest}
                />
              </SliderWrapper>
            </div>
          )
        ) : (
          <ZemplinLoaderWrapper height={250} />
        )}
      </>
    );
  };

  const renderAccessories = () => {
    return (
      <>
        {!isFetchingAccessories ? (
          accessoriesProducts &&
          accessoriesProducts.products &&
          accessoriesProducts.products.length > 0 && (
            <div ref={accessoriesRef}>
              <AccessorySliderWrapper>
                <Title>{__('Príslušenstvo')}</Title>
                <SecondSlider
                  user={user}
                  type="product"
                  slides={accessoriesProducts.products}
                  addToCart={addToCart}
                  addToRequest={addToRequest}
                />
              </AccessorySliderWrapper>
            </div>
          )
        ) : (
          <ZemplinLoaderWrapper height={300} />
        )}
      </>
    );
  };

  const addToCart = (product: any) => {
    dispatch(setAddToCartModalVisibility(false, product));
  };

  const addToRequest = (product: any) => {
    dispatch(setAddToCartModalVisibility(true, product));
  };

  const addToCartWithoutModal = () => {
    const trueCount = getZemplinTrueCount(unit === 'Mks', count);
    dispatch(addItemToCart(product, trueCount));
  };

  const addToRequestWithoutModal = () => {
    const getProductTrueCount = isMks ? Math.round(count * 1000) : count;
    dispatch(addItemToCart(product, getProductTrueCount, true));
  };

  const closeImageModal = () => {
    setImageModalVisible(false);
  };

  const setCurrentImage = c => {
    setModalCurrentImage(c);
  };

  const openModal = (index: number = 0) => {
    setImageModalVisible(true);
    setModalCurrentImage(index);
  };

  const isBartDeveloper = cookies.load('BART') === 'test';

  return (
    <>
      <Wrapper className="container container--wide">
        <MetaTags
          canonicalUrl={`${process.env.REACT_APP_BASE_URL}/${prop(
            product,
            'url',
          )}`}
          tags={getProductMetaTags(product)}
        />
        {isFetching ? (
          <ZemplinLoaderWrapper height={300} />
        ) : product ? (
          <>
            {isBartDeveloper && (
              <>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://helios.zemplin.sk/api/message/product?id=${prop(
                    product,
                    'helios_id',
                  )}`}
                >
                  [HELIOS PRODUCT]
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://helios.zemplin.sk/api/message/pricelist?id=${prop(
                    product,
                    'helios_id',
                  )}`}
                >
                  [HELIOS PRICE]
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://helios.zemplin.sk/api/message/stockstatus?id=${prop(
                    product,
                    'helios_id',
                  )}`}
                >
                  [HELIOS STOCK]
                </a>
              </>
            )}
            {renderUpperPart()}
            {renderBottomPart()}
            {renderAccessories()}
            {renderRelated()}
            {imageModalVisible &&
              fullscreenImages &&
              fullscreenImages.length > 0 && (
                <Modal size="big" showX={false} closeModal={closeImageModal}>
                  <ModalDesktopWrapper>
                    <ImageGallery
                      closeModal={closeImageModal}
                      setCurrentImage={setCurrentImage}
                      items={fullscreenImages}
                      currentIndex={modalCurrentImage}
                      isVertical={false}
                    />
                  </ModalDesktopWrapper>

                  <ModalMobileWrapper>
                    <ModalMobile
                      src={fullscreenImages}
                      currentIndex={modalCurrentImage}
                      closeModal={closeImageModal}
                      setCurrentImage={setCurrentImage}
                    ></ModalMobile>
                  </ModalMobileWrapper>
                </Modal>
              )}
          </>
        ) : (
          <InfoText
            info={__('Nenašiel sa žiadny produkt')}
            type={InfoType.ERROR}
          />
        )}
      </Wrapper>
      <InfoBanner />
    </>
  );
}

const Wrapper = styled(FlexCol)`
  padding: ${rem(56)} ${rem(24)} ${rem(80)};
  color: #444444;
  width: 100%;

  ${({ theme }) => theme.mediab.l925`
      padding: ${rem(24)} ${rem(24)};
  `}
`;

const SliderWrapper = styled(FlexCol)``;

const AccessorySliderWrapper = styled(SliderWrapper)`
  margin-bottom: ${rem(56)};

  ${({ theme }) => theme.mediab.l925`
     margin-bottom: ${rem(32)};
  `}
`;

const Title = styled.h4`
  font-size: ${rem(30)};
  font-weight: 400;
  line-height: ${rem(38)};
  font-family: ${({ theme }) => theme.font.primary};
  margin: 0;
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.m580`
    font-size: ${rem(20)};
    line-height: ${rem(28)};
  `}
`;

const UpperWrapper = styled(FlexRow)`
  justify-content: space-between;
  margin-bottom: ${rem(16)};

  ${({ theme }) => theme.mediab.l925`
    flex-flow: column;
  `}
`;

const ModalMobileWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mediab.l925`
    display: flex;
  `}
`;

const ModalDesktopWrapper = styled.div`
  display: flex;
  ${({ theme }) => theme.mediab.l925`
    display: none;
  `}
`;

const UpperMobileWrapper = styled(UpperWrapper)`
  display: none;

  ${({ theme }) => theme.mediab.l925`
    display: flex;
    margin:0 auto;
  `}

  ${({ theme }) => theme.mediab.s450`
    margin: initial;
  `}
`;

const UpperDesktopWrapper = styled(UpperWrapper)`
  ${({ theme }) => theme.mediab.l925`
    display: none;
  `}
`;

const ImageWrapper = styled.div<{ imgSrc: string }>`
  width: 95%;
  position: relative;
`;

const GalleryDesktopWrapper = styled(UpperWrapper)`
  margin: 0;
  ${({ theme }) => theme.mediab.l925`
    display: none;
  `}
`;

const GalleryWrapper = styled(FlexRowCenterVertical)`
  margin: ${rem(16)} 0 ${rem(48)};
`;

const Thumb = styled.img`
  width: ${rem(80)};
  height: ${rem(80)};
  margin-right: ${rem(16)};
  cursor: pointer;
  border-radius: ${rem(4)};
`;

const MoreThumb = styled(FlexColCenter)`
  width: ${rem(80)};
  height: ${rem(80)};
  margin-right: ${rem(16)};
  border: ${rem(1)} solid #ccc;
  cursor: pointer;
  border-radius: ${rem(4)};
  font-size: ${rem(32)};
  color: #ccc;
`;

export default withRouter(Product);
