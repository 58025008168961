import React from 'react';
import styled from 'styled-components';
import { prop } from '../../utilities';
import { FlexCol, FlexRowCenter, TextP } from 'eshop-defaults';
import { rem } from 'polished';
import { __ } from 'react-i18n';

interface Props {
  userData: any;
  createActivity: any;
  impersonateUser: any;
}

export function CrmDashboardHeader(props: Props) {
  const {
    name,
    surname,
    email,
    contact_email_1,
    contact_email_2,
    contact_email_3,
    ico,
    crm_obchodnik_email,
    eshop_user,
    firma,
    id,
  } = props.userData;

  const { createActivity, impersonateUser } = props;

  return (
    <Wrapper>
      <PhotoNameWrapper>
        <Photo>{prop(firma, '0')}</Photo>
        <NameWrapper>
          <Name bold={'bold'}>{` ${firma ? firma : name} `}</Name>
          <Contact>{email}</Contact>
          <Contact>{`${ico ? `${__('ICO')}: ${ico}` : ''}`}</Contact>
          <Contact>{`${
            crm_obchodnik_email ? `${__('CRM')}: ${crm_obchodnik_email}` : ''
          }`}</Contact>
          <Contact>{`${
            contact_email_1
              ? `${__('Zodpovedná osoba')}: ${contact_email_1}`
              : ''
          }`}</Contact>
          <Contact>{`${
            eshop_user
              ? `${__('Eshop zákazník')}: ${eshop_user ? 'áno' : 'nie'}`
              : 'Eshop zákazník: nie'
          }`}</Contact>
          <Contact>{`${
            contact_email_2
              ? `${__('Zodpovedná osoba VT')}: ${contact_email_2}`
              : ''
          }`}</Contact>
          <Contact>{`${
            contact_email_3
              ? `${__('Zodpovedná osoba BO')}: ${contact_email_3}`
              : ''
          }`}</Contact>
        </NameWrapper>
      </PhotoNameWrapper>
      <div style={{ display: 'flex', flexFlow: 'row' }}>
        <AddActivity onClick={createActivity}>+</AddActivity>
        <Impersonate onClick={() => impersonateUser(id)}>></Impersonate>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  padding: 12px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  align-items: center;
  justify-content: space-between;
`;

const PhotoNameWrapper = styled.div`
  width: 400px;
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const NameWrapper = styled.div`
  width: 70%;
  display: flex;
  flex-flow: column;
`;

const Photo = styled(FlexRowCenter)`
  width: ${rem(48)};
  height: ${rem(48)};
  text-transform: uppercase;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: ${rem(24)};
  margin-right: ${rem(16)};
  color: white;
  font-weight: 700;
  font-size: ${rem(20)};
`;

const AddActivity = styled(FlexRowCenter)`
  width: ${rem(48)};
  height: ${rem(48)};
  text-transform: uppercase;
  background: #26bf38;
  border-radius: ${rem(24)};
  margin-right: ${rem(16)};
  color: white;
  font-weight: 700;
  font-size: ${rem(20)};

  &:hover {
    background: #61e871;
    cursor: pointer;
  }
`;

const Impersonate = styled(FlexRowCenter)`
  width: ${rem(48)};
  height: ${rem(48)};
  text-transform: uppercase;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: ${rem(24)};
  margin-right: ${rem(16)};
  color: white;
  font-weight: 700;
  font-size: ${rem(20)};

  &:hover {
    background: #5f54b8;
    cursor: pointer;
  }
`;

const Contact = styled(TextP)`
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  font-size: ${rem(12)};
`;

const Name = styled(TextP)`
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  font-weight: ${props => props.bold || '500'};
`;
